import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reducers } from '../store/configurePresentStore';
import withReducers from '../store/withReducers';
import { ErrorBoundary, Theme, Toggle, ToggleLink, LabeledInput, Button } from '@commonsku/styles';
import { WebsocketContextProvider } from '../context/websocket-context';
import Header from './Header';
import MainSection from './MainSection';
import createPopup from '../popup-factory';
import Overlay from '../components/Overlay';
import { getPopups } from '../selectors';
import { oauth } from '../utils';

class TwoFactorSetupApp extends Component {
    constructor(props) {
        super(props);

        const { identity } = this.props;

        this.state = {
            twoFactorOn: identity.security_2fa,
            needVerification: false,
            pinInvalid: false,
            setupSuccessful: false,
            pin: '',
            login_password: '',
            new_password: '',
            new_password_confirm: '',
            password_error: '',
            password_success: '',
        };

        this.handleAllowTwoFactor = this.handleAllowTwoFactor.bind(this);
        this.verifyCode = this.verifyCode.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount() {
        // this.verifyCode();
    }

    async handleAllowTwoFactor(toggle) {
        const toggleValue = toggle == 1 ? 1 : 0;

        this.setState({
            needVerification: true,
            twoFactorOn: toggleValue,
        });
        return;
    }

    async verifyCode() {
        let res = '';
        const targetValue = this.state.twoFactorOn;

        try {
            res = await oauth('GET', 'login/verify-two-factor', {
                code: this.state.pin,
                setup: 1,
            });
        } catch (error) {
            console.log('Failed to save 2fa setting');
            this.setState({
                twoFactorOn: targetValue == 1 ? 0 : 1,
                needVerification: false,
                pinInvalid: true,
            });
            return;
        }
        if (res.json.data.verified) {
            try {
                await oauth('GET', 'login/toggle-two-factor', {
                    security_2fa: targetValue,
                });
                this.setState({
                    twoFactorOn: targetValue == 1 ? 1 : 0,
                    needVerification: false,
                    setupSuccessful: true,
                    pinInvalid: false,
                });
            } catch (error) {
                console.log('Failed to save 2fa setting');
                this.setState({
                    twoFactorOn: targetValue == 1 ? 0 : 1,
                    needVerification: false,
                    pinInvalid: true,
                });
            }
        } else {
            this.setState({
                twoFactorOn: targetValue == 1 ? 0 : 1,
                pin: '',
                needVerification: false,
                pinInvalid: true,
            });
        }
    }

    async changePassword() {
        if (this.state.login_password.length === 0) {
            this.setState({
                password_error: 'Your current password is required to change your password.',
            });
            return false;
        }
        if (this.state.new_password.length < 5) {
            this.setState({
                password_error: 'Passwords must be at least 5 characters long.',
            });
            return false;
        }
        if (this.state.new_password !== this.state.new_password_confirm) {
            this.setState({
                password_error: 'Your password confirmation does not match your new password.',
            });
            return false;
        }

        try {
            await oauth('PUT', 'password/change', {
                login_password: this.state.login_password,
                new_password: this.state.new_password,
            });
            this.setState({
                login_password: '',
                new_password: '',
                new_password_confirm: '',
                password_error: '',
                password_success: 'Your password has been successfully changed.',
            });
            return true;
        } catch (error) {
            this.setState({
                password_error: 'Failed to change password.',
            });
            return false;
        }
    }

    render() {
        const { identity, security2fa } = this.props;
        return (
            <Theme>
                <ErrorBoundary>
                    <Header>
                        <div className="columns">
                            <h4 style={{ display: 'inline-block' }}>Security Setup</h4>
                        </div>
                    </Header>
                    <WebsocketContextProvider
                        identity={identity}
                        channel={identity.user_id}
                        tenantOptions={{ chat_welcome_message: 'default' }}
                        useChatSession={false}
                    >
                        <MainSection>
                            <div className="main-content shop-config-container">
                                <div className="row section-checkout" style={{ padding: '15px', maxWidth: 'unset', minHeight: '600px' }}>
                                    <div className="medium-12 large-6 columns" style={{ marginBottom: '15px' }}>
                                        <h3>Two factor login</h3>
                                        <div>
                                            <p>Your two factor authentication is</p>
                                            <div>
                                                <Toggle>
                                                    <ToggleLink id="1" selected={this.state.twoFactorOn == 1} onClick={e => { e.preventDefault(); this.handleAllowTwoFactor(1); }}>On</ToggleLink>
                                                    <ToggleLink id="0" selected={this.state.twoFactorOn == 0} onClick={e => { e.preventDefault(); this.handleAllowTwoFactor(0); }}>Off</ToggleLink>
                                                </Toggle>
                                            </div>
                                        </div>
                                        {this.state.twoFactorOn == 1 && <div style={{ marginTop: '3rem' }}>
                                            <div>Scan the following QR code below to your Authenticator App. Use the 6 digit pin provided from your app to finish your login.</div>
                                            <div>More information about 2FA and how it can protect you.</div>
                                            <div style={{ marginTop: '1rem' }}><img src={security2fa.qr} alt="Please reload if the QR code is not shown." /></div>
                                        </div>}

                                        {this.state.needVerification && <div style={{ marginTop: '3rem' }}>
                                            <LabeledInput
                                                style={{ width: '15em' }}
                                                autocomplete="off"
                                                name="Two Factor Pin Verification"
                                                value={this.state.pin}
                                                onChange={e => this.setState({
                                                    pin: e.target.value
                                                })}
                                                placeholder="Enter 6 digit pin to continue"
                                            />
                                            <Button onClick={() => { this.verifyCode(); }}>Confirm and Change 2FA</Button>
                                        </div>}
                                        {this.state.setupSuccessful && <p style={{ color: "green" }}>You have successfully changed your 2FA setting!</p>}
                                        {this.state.pinInvalid && <p style={{ color: 'red' }}>Failed to change set up since the 6 digit pin is invalid. Please try again.</p>}
                                    </div>
                                    <div className="medium-12 large-6 columns" style={{ marginBottom: '15px' }}>
                                        <h3>Change Password</h3>
                                        <div>
                                            <LabeledInput
                                                style={{ width: '15em' }}
                                                type="password"
                                                name="login_password"
                                                value={this.state.login_password}
                                                onChange={e => this.setState({
                                                    login_password: e.target.value
                                                })}
                                                placeholder="Current Password"
                                            />
                                            <LabeledInput
                                                style={{ width: '15em' }}
                                                type="password"
                                                name="new_password"
                                                value={this.state.new_password}
                                                onChange={e => this.setState({
                                                    new_password: e.target.value
                                                })}
                                                placeholder="New Password"
                                            />
                                            <LabeledInput
                                                style={{ width: '15em' }}
                                                type="password"
                                                name="new_password_confirm"
                                                value={this.state.new_password_confirm}
                                                onChange={e => this.setState({
                                                    new_password_confirm: e.target.value
                                                })}
                                                placeholder="Confirm New Password"
                                            />
                                            <Button onClick={() => { this.changePassword(); }}>Reset Password</Button>
                                            <div>
                                                {this.state.password_success !== '' && <p style={{ color: "green" }}>{this.state.password_success}</p>}
                                                {this.state.password_error !== '' && <p style={{ color: "red" }}>{this.state.password_error}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MainSection>
                    </WebsocketContextProvider>
                    <div className="resku">
                        {this.props.popups ? this.props.popups.map((p, idx) => createPopup(p, idx, this.props)) : null}
                    </div>
                    <div className="resku">
                        <Overlay popups={this.props.popups} />
                    </div>
                </ErrorBoundary>
            </Theme>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        identity: state.identity,
        security2fa: state.security2fa,
        popups: getPopups(state),
    };
};

export default withReducers(connect(mapStateToProps)(TwoFactorSetupApp), reducers, true);
